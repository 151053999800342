import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { CustomTable } from '../../../components/atoms';
import { getAdminOrders } from '../../../network/services/admin/order';
import { newOrderColumn, orderColumns } from '../data';

function PendingOrdersTable() {
  const [page, setPage] = useState(1);
  const pageSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE ? parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE) : 10;
  const { data, isFetchedAfterMount, isLoading } = useQuery(
    ['admin_orders', { page: page - 1, size: pageSize, type: 'PENDING' }],
    getAdminOrders
    // {
    //   staleTime: Infinity
    // }
  );
  const orders = data?.data || [];

  // const pendingOrders = orders.filter((order: any) => order.status.toLowerCase() === 'pending');

  return (
    <CustomTable
      isLoading={isLoading}
      columns={newOrderColumn}
      data={orders}
      totalItems={data?.totalElements ?? 0}
      totalPages={data?.totalPages ?? 0}
      currentPage={page}
      setCurrentPage={setPage}
    />
  );
}

export default PendingOrdersTable;
