import { ChangeEvent, useState } from 'react';
import { DashboardHeader, TopHeaderCard } from '../../components/molecules';
import { orderColumns, filter, newOrderColumn } from './data';
import { OrderCON, OrderTableCON, TableHeaderCON } from './style';
import { CustomTable, SelectField, Spacer } from '../../components/atoms';
import { BrokenGraph, BrokenGclose, AdminUsersIcon } from '../../assets/Icons';
import { orderFilter } from '../../store/atoms/orderAtom';
import { FILTER_KEYS } from '../../store/keys';
import { useRecoilState } from 'recoil';
import { useQuery } from 'react-query';
import { getAdminOrders } from '../../network/services/admin/order';
import { getAdminOrdersAnalytics } from '../../network/services/admin/order';
import PendingOrdersTable from './Pending';
import CancelledOrdersTable from './Cancelled';
import CompletedOrdersTable from './Completed';

export const OrderList = () => {
  const [orderFilterState, setOrderFilterState] = useRecoilState(orderFilter);
  const [page, setPage] = useState(1);
  const pageSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE ? parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE) : 10;
  const { data, isFetchedAfterMount, isLoading } = useQuery(
    ['admin_orders', { page: page - 1, size: pageSize, type: orderFilterState }],
    getAdminOrders
    // {
    //   staleTime: Infinity
    // }
  );
  const orders = data?.data || [];

  const { data: analyticsData } = useQuery(['admin_orders_analytics'], getAdminOrdersAnalytics, {
    staleTime: Infinity,
    retry: false
  });
  const analytics = analyticsData?.data || {};

  const topCardData = [
    {
      title: 'All Orders',
      total: analytics?.all,
      Icon: BrokenGraph,
      iconBg: '#F5FBEF'
      // percentage: '+35.5%'
    },
    // {
    //   title: 'New Orders',
    //   total: analytics?.newOrder,
    //   Icon: BrokenBox,
    //   iconBg: '#FEF9EC',
    //   // percentage: '+35.5%'
    // },

    {
      title: 'Completed Orders',
      total: analytics?.completed,
      Icon: AdminUsersIcon,
      // percentage: '-3.93%',
      iconBg: '#EFF1FB'
    },
    {
      title: 'Ongoing Orders',
      total: analytics?.ongoing,
      // percentage: '-26.3%',
      Icon: AdminUsersIcon,
      iconBg: '#FFF0F3'
    },
    {
      title: 'Pending Orders',
      total: analytics?.pending,
      // percentage: '+25.3%',
      Icon: BrokenGclose,
      iconBg: '#FFF0F3'
    },

    {
      title: 'Cancelled Orders',
      total: analytics?.cancelled,
      // percentage: '-26.3%',
      Icon: BrokenGclose,
      iconBg: '#FFF0F3'
    }
  ];

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOrderFilterState(e.target.value);
  };

  return (
    <OrderCON>
      <DashboardHeader title="Order" />
      <TopHeaderCard data={topCardData.slice(0, 5)} />
      <Spacer height="48px" />

      <OrderTableCON>
        <TableHeaderCON>
          {orderFilterState.includes(FILTER_KEYS.ALL_ORDERS) ? (
            <h3>All Orders</h3>
          ) : orderFilterState.includes(FILTER_KEYS.PENDING_ORDERS) ? (
            <h3>Pending Orders</h3>
          ) : orderFilterState.includes(FILTER_KEYS.ONGOING_ORDERS) ? (
            <h3>Ongoing Orders</h3>
          ) : orderFilterState.includes(FILTER_KEYS.CANCELED_ORDERS) ? (
            <h3>Cancelled Orders</h3>
          ) : (
            orderFilterState.includes(FILTER_KEYS.COMPLETED_ORDER) && <h3>Completed Orders</h3>
          )}

          <SelectField handleChange={handleFilterChange} options={filter} value={orderFilterState} />
        </TableHeaderCON>
        {orderFilterState.includes(FILTER_KEYS.ALL_ORDERS) ? (
          <CustomTable
            isLoading={isLoading}
            columns={newOrderColumn}
            data={orders}
            totalItems={data?.totalElements ?? 0}
            totalPages={data?.totalPages ?? 0}
            currentPage={page}
            setCurrentPage={setPage}
          />
        ) : orderFilterState.includes(FILTER_KEYS.ONGOING_ORDERS) ? (
          <CustomTable
            isLoading={isLoading}
            columns={newOrderColumn}
            data={orders}
            totalItems={data?.totalElements ?? 0}
            totalPages={data?.totalPages ?? 0}
            currentPage={page}
            setCurrentPage={setPage}
          />
        ) : orderFilterState.includes(FILTER_KEYS.PENDING_ORDERS) ? (
          <PendingOrdersTable />
        ) : orderFilterState.includes(FILTER_KEYS.CANCELED_ORDERS) ? (
          <CancelledOrdersTable />
        ) : (
          orderFilterState.includes(FILTER_KEYS.COMPLETED_ORDER) && <CompletedOrdersTable />
        )}
      </OrderTableCON>
    </OrderCON>
  );
};
